import React, { useEffect, useState } from "react";
import { Props } from "./types";
import { Modal } from "src/components/base/Modal";
import { Button, Text, VStack } from "@chakra-ui/react";

const RemoveConfirmation: React.FC<Props> = ({
  handleConfirm,
  handleClose,
  note,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [note]);

  return (
    <Modal isOpen={!!note} title={"Excluir nota"} onClose={handleClose}>
      <VStack borderTop={"1px"} borderTopColor={"Gray.$300"} spacing={0}>
        <VStack p={"24px"}>
          <Text fontSize={"LMD"}>
            Tem certeza que deseja remover essa nota? Você não poderá desfazer
            essa ação.
          </Text>
        </VStack>

        <VStack
          w={"100%"}
          py={"16px"}
          px={"24px"}
          spacing={"8px"}
          borderTop={"1px"}
          borderTopColor={"Gray.$300"}
        >
          <Button
            w={"100%"}
            bg={"#DD1940"}
            _hover={{
              background: "#bd1134",
            }}
            color={"Gray.$100"}
            borderRadius={"8px"}
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true);
              handleConfirm();
            }}
          >
            Excluir
          </Button>

          <Button
            onClick={handleClose}
            isLoading={isLoading}
            borderRadius={"8px"}
            w={"100%"}
          >
            Cancelar
          </Button>
        </VStack>
      </VStack>
    </Modal>
  );
};

export default RemoveConfirmation;
