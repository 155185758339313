import React, { useEffect } from "react";
import { Props } from "./types";
import { Controller, useForm } from "react-hook-form";
import { Modal } from "src/components/base/Modal";
import { Box, Button, Center, VStack } from "@chakra-ui/react";
import Input from "src/components/base/Input/Index";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { MutatingDots } from "react-loader-spinner";

const NoteForm: React.FC<Props> = ({ handleClose, handleSubmitForm, note }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({ defaultValues: note });

  useEffect(() => {
    if (note && "id" in note) {
      reset(note);
    }
    reset();
  }, [note, reset]);

  return (
    <Modal title={"Editar nota"} isOpen={!!note} onClose={handleClose}>
      {isSubmitting ? (
        <Center width={"100%"} height={"300px"}>
          <MutatingDots
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <VStack maxW={"568px"}>
          <VStack
            borderTop={"1px solid"}
            borderColor={"Gray.$300"}
            p={"16px 24px"}
            spacing={4}
            w={"100%"}
          >
            <Controller
              control={control}
              name="title"
              render={({ field }) => (
                <Input {...field} placeholder="Título da nota" />
              )}
            />

            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <Box width={"100%"}>
                  <CKEditor
                    editor={ClassicEditor as any}
                    data={field.value}
                    onChange={(_, editor) => {
                      const data = (editor as any).getData();
                      field.onChange(data);
                    }}
                    config={{
                      placeholder: "Mensagem para a empresa",
                      toolbar: {
                        items: [
                          "undo",
                          "redo",
                          "|",
                          "heading",
                          "|",
                          "bold",
                          "|",
                          "italic",
                          "|",
                          "numberedList",
                          "bulletedList",
                          "link",
                          "indent",
                        ],
                      },
                    }}
                  />
                </Box>
              )}
            />
          </VStack>

          <Center
            p={"16px"}
            borderTop={"1px solid"}
            borderTopColor={"Gray.$300"}
            w={"100%"}
          >
            <Button
              variant={"Primary"}
              borderRadius={"8px"}
              w={"100%"}
              onClick={handleSubmit(handleSubmitForm)}
            >
              Salvar
            </Button>
          </Center>
        </VStack>
      )}
    </Modal>
  );
};

export default NoteForm;
