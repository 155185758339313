import _axios from "..";
import { Company, Note, Project } from "../../types";

export const NoteService = {
  create: async (body: Note): Promise<Note> =>
    (await _axios.post("/notes", body)).data,

  update: async (note_id: Note["id"], note: Note): Promise<void> =>
    (await _axios.put(`/note/${note_id}`, note)).data,

  getByProjectAndCompany: async (
    project_id: Project["id"],
    company_id: Company["id"]
  ): Promise<Note[]> =>
    (await _axios.get(`/notes/project/${project_id}/company/${company_id}`))
      .data,

  remove: async (company_id: Company["id"]): Promise<void> =>
    (await _axios.delete(`/note/${company_id}`)).data,
};

export default NoteService;
