import React, { useEffect, useState } from "react";
import { Props } from "./types";
import { Modal } from "src/components/base/Modal";
import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { ProfileImage } from "semente-js";
import { formatDate } from "src/common";

const NoteVisualizer: React.FC<Props> = ({
  handleUpdate,
  handleRemove,
  handleClose,
  note,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [note]);

  return (
    <Modal isOpen={!!note} title={"Nota"} onClose={handleClose}>
      <VStack
        borderTop={"1px"}
        maxW={"568px"}
        borderTopColor={"Gray.$300"}
        spacing={0}
      >
        <VStack p={"24px"} alignItems={"flex-start"} w={"100%"}>
          <Text fontSize={"32px"} fontWeight={700}>
            {note?.title}
          </Text>
          <Text
            fontSize={"LMD"}
            fontWeight={400}
            mt={"36px"}
            dangerouslySetInnerHTML={{ __html: note?.description || "" }}
          />

          <VStack mt={"36px"}>
            <HStack>
              <ProfileImage
                initials={`${note?.createdBy?.name.substring(0, 1)}${note?.createdBy?.surname.substring(0, 1)}`}
              />
              <VStack alignItems={"flex-start"} spacing={0}>
                <Text fontSize={"12px"} fontWeight={600}>
                  {note?.createdBy?.name} {note?.createdBy?.surname}
                </Text>

                <Text fontSize={"12px"} fontWeight={400}>
                  {formatDate(new Date(note?.createdAt || ""), "dd/MM/yyyy")}
                </Text>
              </VStack>
            </HStack>
          </VStack>
        </VStack>

        <HStack
          w={"100%"}
          py={"16px"}
          px={"24px"}
          spacing={"8px"}
          borderTop={"1px"}
          borderTopColor={"Gray.$300"}
        >
          <Button
            w={"100%"}
            bg={"#DD1940"}
            _hover={{
              background: "#bd1134",
            }}
            color={"Gray.$100"}
            borderRadius={"8px"}
            isLoading={isLoading}
            onClick={handleRemove}
          >
            Excluir
          </Button>

          <Button
            onClick={handleUpdate}
            isLoading={isLoading}
            borderRadius={"8px"}
            w={"100%"}
          >
            Editar
          </Button>
        </HStack>
      </VStack>
    </Modal>
  );
};

export default NoteVisualizer;
