import { Box, Center, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { MutatingDots } from "react-loader-spinner";
import { KanbanContext } from "src/contexts/KanbanContext";
import Navbar from "./Navbar";
import * as Sections from "./Sections";
import Icon from "src/components/base/Icon";
import { useNavigate, useParams } from "react-router-dom";
import { getAvailableTabs } from "./common";

const TaskView: React.FC = () => {
  const { currentDialog, kanbanData, selectDialog } = useContext(KanbanContext);
  const { id, tab } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentDialog?.props?.task && id && tab) {
      selectDialog({
        key: "taskView",
        props: {
          task: { id } as any,
        },
        isLoading: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SectionComponent = (Sections as any)[tab as any];

  return (
    <VStack w={"100%"} h={"100%"} alignItems={"flex-start"} p={"64px 80px"}>
      {currentDialog?.isLoading || !tab ? (
        <Center w={"100%"} h={"100%"}>
          <MutatingDots
            height="100"
            width="100"
            color="#408EC5"
            secondaryColor="#408EC5"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            visible={true}
          />
        </Center>
      ) : (
        <VStack w={"100%"} spacing={0} alignItems={"flex-start"}>
          <HStack pb={"24px"}>
            <Text
              textDecor={"underline"}
              fontWeight={500}
              cursor={"pointer"}
              onClick={() => {
                navigate("/?tab=1");
              }}
              fontSize={"L_md"}
              color={"Gray.$800"}
            >
              {kanbanData?.data?.name}
            </Text>
            <Text fontWeight={500} fontSize={"L_md"} color={"Gray.$800"}>
              / {currentDialog?.props?.task?.company?.name}
            </Text>
          </HStack>

          <HStack pb={"24px"}>
            <Box
              cursor={"pointer"}
              onClick={() => {
                navigate("/?tab=1");
              }}
            >
              <Icon name={"arrow-left"} size="32px" />
            </Box>
            <Text fontWeight={500} fontSize={"RH_xl"} color={"Gray.$800"}>
              {currentDialog?.props?.task?.company?.name}
            </Text>
          </HStack>

          <VStack
            pt={"24px"}
            w={"100%"}
            borderTop={"1px solid"}
            borderColor={"Gray.$400"}
          >
            {currentDialog?.props?.task && currentDialog.props.column && (
              <HStack
                h={"100%"}
                w={"100%"}
                spacing={"36px"}
                alignItems={"flex-start"}
              >
                <Navbar
                  availableTabs={getAvailableTabs(
                    currentDialog?.props?.column?.position
                  )}
                  activeOption={tab}
                  onClick={(e) =>
                    navigate(
                      `/kanban/task/${e}/${currentDialog?.props?.task?.id}`
                    )
                  }
                />

                <VStack flex={1} h={"100%"} m={"0px !important"} maxW={"720px"}>
                  <SectionComponent
                    task={currentDialog.props.task}
                    column={currentDialog?.props?.column}
                  />
                </VStack>
              </HStack>
            )}
          </VStack>
        </VStack>
      )}
    </VStack>
  );
};

export default TaskView;
